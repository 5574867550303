import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  lng: "fr",
  fallbackLng: "fr",
  resources: {
    es: {
      translation: {},
    },
    en: {
      translation: {},
    },
    fr: {
      translation: {},
    },
  },
});
