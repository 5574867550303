import { useRef, useImperativeHandle, forwardRef, useState } from "react";

import { useTranslation } from "react-i18next";

import instagram from "../../assets/pngINSTA.png";
import whatsapp from "../../assets/pngWHATSAPP.png";

const Contact = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const componentRef = useRef();
  const scrollToContact = () => {
    componentRef.current.scrollIntoView({ behavior: "smooth" });
  };
  useImperativeHandle(ref, () => {
    return {
      scroll: scrollToContact,
    };
  });

  return (
    <section
      ref={componentRef}
      className="h-fit relative w-full p-5 md:p-20 flex items-center text-white contact"
    >
      <div className="flex flex-col justify-start md:px-20">
        <h2 className="text-2xl lg:text-4xl font-bold pb-4 sm:pb-12 pt-5 sm:pt-10 lg:pt-0">
          Hacé tu reserva ya!
        </h2>
        <div className="flex flex-col text-base sm:text-2xl">
          <p className="mb-3 sm:mb-6">Whatsapp: +54 9 381 3965555</p>
          <div className="">
            <h3 className="font-semibold">Seguinos en Instagram <a href="#">@tresvientos.raco</a></h3>
            <div className="flex gap-4">
              <a href="https://www.instagram.com/tresvientos.raco/" target="_BLANK">
                <img
                  alt="redsocial"
                  src={instagram}
                  className="w-8 h-8 sm:h-12 sm:w-12 mt-4 hover:bg-[rgba(0,0,0,0.4)] rounded-md"
                />
              </a>
              <a href="https://wa.link/mqn14g" target="_BLANK">
                <img
                  alt="redsocial"
                  src={whatsapp}
                  className="w-8 h-8 sm:h-12 sm:w-12 mt-4 hover:bg-[rgba(0,0,0,0.4)] rounded-md"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 left-2">Hecho por Santiago Ruiz</div>
    </section>
  );
});

export default Contact;
