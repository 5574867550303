import { useRef, useImperativeHandle, forwardRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";

import raco4 from "../../assets/raco3.jpg";
import refinor from "../../assets/Refinor.jpg";
import food from "../../assets/food.jpg";
import monasterio from "../../assets/monasterio.jpg";
import golf from "../../assets/golf.jpg";

import { useTranslation } from "react-i18next";

const Interesting = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const componentRef = useRef();
  const scrollToInteresting = () => {
    componentRef.current.scrollIntoView({ behavior: "smooth" });
  };
  useImperativeHandle(ref, () => {
    return {
      scroll: scrollToInteresting,
    };
  });

  const lugares = [
    {
      title: "Monasterio Benedictino",
      text: ["Sitio de gran espiritualidad, el Monasterio de Cristo Rey fue construido íntegramente con piedras y maderas de la zona de El Siambón, a mediados de la década del ’50, y por las propias manos de los Monjes Benedictinos que allí vivirían."],
      photo: monasterio,
    },
    {
      title: "Restaurantes",
      text: ["El Portezuelo 381 691-4362.", "The Gaucho Experience 381 565-6028.", "La Tuquita Restaurante y Eventos 381 518-0282."],
      photo: food,
    },
    {
      title: "Atahualpa Refinor Raco",
      text: ["Estación de servicio que provee suministros necesarios para tu estadia en Raco."],
      photo: refinor,
    },
    {
      title: 'Siambón Country Club',
      text: ['Sus canchas rodeadas de bellos paisajes, invitan a sumergirse en un ambiente de paz y tranquilidad mientras se practica esta fascinante actividad.'],
      photo: golf,
    }
  ];

  return (
    <section ref={componentRef} className="">
      <div>
        <div className="relative h-[40vh] sm:h-[33vh]">
          <div className="absolute text-[#9CC4B2] top-0 left-0 p-2 sm:p-10 lg:py-20 lg:px-40 z-[2]">
            <h2 className="text-xl sm:text-2xl font-bold">Raco</h2>
            <p className="mt-2 md:mt-10 font-normal text-xs sm:text-base">
              Bella localidad ubicada al Noroeste de la provincia de Tucumán.
              Rodeada de altas montañas, con vistas panorámicas del pueblo y
              picos más alejados que se tapan con las nubes. Sumida en la
              tranquilidad y quietud, Raco es un lugar que invita al contacto
              con la naturaleza.
            </p>
          </div>
          <div className="bg-[#00000088] absolute top-0 left-0 h-full w-full z-[1]" />
          <img
            loading="lazy"
            src={raco4}
            alt="raco"
            className="absolute object-cover z-0 top-0 left-0 w-full h-full"
          />
        </div>
        <div className="flex flex-col items-center justify-center">
          <div className="w-full text-center font-bold text-xl sm:text-3xl mt-10 sm:mt-20">
            Lugares de interes
          </div>
          <div className="w-full lg:w-4/5 sm:p-5 md:p-10 pt-2 int-swiper">
            <Swiper
              effect="slide"
              centeredSlides={true}
              pagination
              navigation
              modules={[Pagination, Navigation]}
            >
              {lugares.map((e, i) => (
                <SwiperSlide>
                  <div className="relative h-full max-h-[500px]">
                    <img
                      src={e.photo}
                      loading="lazy"
                      alt=""
                      className="absolute z-[-1] top-0 left-0 object-cover h-full w-full"
                    />
                    <div className="bg-[#000000ad] h-full z-10 text-white text-shadow p-5 sm:p-20">
                      <h2 className="font-bold text-lg sm:text-2xl mb-2 sm:mb-10">{e.title}</h2>
                      {e.text.map((p,i)=>(
                        <p key={i} className="text-xs sm:text-lg">{p}</p>
                      ))}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Interesting;
