import { useState } from "react";

import { useTranslation } from 'react-i18next';

import MediaQuery from 'react-responsive'
import menuBars from '../../assets/menu-bars.png';

const Header = (props) => {
  const {t, i18n} = useTranslation();
  const [showMenu, setShowMenu] = useState(false);

  const openMenu = () => {
    setShowMenu((prevState)=>{
      return !prevState;
    })
  };

  return (
    <>
      <MediaQuery minWidth={640}>
        <header
          className="w-fit fixed top-0 right-0 z-20 h-fit pr-3 pl-1 pb-1 pt-3 lg:pr-10 lg:pt-10 lg:pb-3 lg:pl-3 flex items-center"
        >
          <ul className="flex w-[75vw] lg:w-[50vw] justify-between text-white font-semibold text-xl">
            <li className="cursor-pointer hover:text-[#FDB54D] transition-colors text-shadow" onClick={props.onHome}>Inicio</li>
            <li className="cursor-pointer hover:text-[#9CC4B2] transition-colors text-shadow" onClick={props.onLofts}>Lofts</li>
            <li className="cursor-pointer hover:text-[#F6C79F] transition-colors text-shadow" onClick={props.onInteresting}>Alrededores</li>
            <li className="cursor-pointer hover:text-[#B08D8C] transition-colors text-shadow" onClick={props.onOpinions}>Opiniones</li>
          </ul>
        </header>
      </MediaQuery>
      <MediaQuery maxWidth={640}>
        <span onClick={openMenu} className='fixed z-30 top-0 right-0 m-1 md:m-3 active:rotate-180'><img src={menuBars} alt='menu' className="w-10 h-10 md:w-14 md:h-14" /></span>
        <header className={`text-white text-2xl font-bold fixed z-20 top-0 ${showMenu ? 'left-0' : 'left-[100vw]'} w-full h-screen bg-[#9CC4B2] p-10 flex flex-col justify-center gap-8 transition-all duration-300`}>
          <span onClick={()=>{props.onHome(); openMenu()}} className="cursor-pointer">Inicio</span>
          <span onClick={()=>{props.onLofts(); openMenu()}} className="cursor-pointer">Lofts</span>
          <span onClick={()=>{props.onInteresting(); openMenu()}} className="cursor-pointer">Alrededores</span>
          <span onClick={()=>{props.onOpinions(); openMenu()}} className="cursor-pointer">Opiniones</span>
        </header>
      </MediaQuery>
      
    </>
  );
};

export default Header;

{/* <div
        onClick={menuSlideOut}
        onMouseLeave={menuSlideOut}
        className={`${slide} z-10 fixed top-0 text-lg lg:text-xl w-full sm:min-w-[500px] sm:w-fit h-screen px-10 transition-all duration-300 bg-[#1291CC]`}
      >
        <ul className="text-white font-semibold h-full flex flex-col justify-center">
          <li
            onClick={props.onHome}
            className="hover:opacity-100 opacity-70 transition-all pt-8"
          >
            {t("header.home")}
          </li>
          <li
            onClick={props.onAbout}
            className="hover:opacity-100 opacity-70 transition-all pt-8"
          >
            {t("header.aboutUs")}
          </li>
          <li
            onClick={props.onServices}
            className="hover:opacity-100 opacity-70 transition-all pt-8"
          >
            {t("header.services")}
          </li>
          <li
            onClick={props.onGalery}
            className="hover:opacity-100 opacity-70 transition-all pt-8"
          >
            {t("header.galery")}
          </li>
          <li
            onClick={props.onContact}
            className="hover:opacity-100 opacity-70 transition-all pt-8"
          >
            {t("header.contact")}
          </li>
        </ul>
      </div> */}