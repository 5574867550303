import React, { useRef, useImperativeHandle, forwardRef } from "react";

import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import star from '../../assets/star.png';

import { useMediaQuery } from "react-responsive";

const Opinions = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const md = useMediaQuery({
    query: '(min-width: 900px)'
  });

  const componentRef = useRef();
  const scrollToOpinions = () => {
    componentRef.current.scrollIntoView({ behavior: "smooth" });
  };
  useImperativeHandle(ref, () => {
    return {
      scroll: scrollToOpinions,
    };
  });

  const opinions = [{
    title: "Booking.com",
    name: "Raúl",
    points: "10",
    coment: "La verdad que el lugar es increíble, y la casa una maravilla, todo nuevo, de muy buen gusto y perfecto estado!!!",
    date: "2022-11-01"
  },{
    title: "Booking.com",
    name: "Pablo",
    points: "9",
    coment: "Las Cabañas hermosas. Todo nuevo e impecable. La decoración. Los espacios. Ideal para parejas.",
    date: "2022-06-13"
  },{
    title: "Booking.com",
    name: "Mariano",
    points: "10",
    coment: "El dpto es genial, y el lugar es muy tranquilo! Excelente para descansar y desconectar. Muy linda la vista a los cerros y los comercios del lugar están relativamente cerca. Miranda y Mariela atentas en todo momento, la pasamos de 10!",
    date: "2022-09-10"
  },{
    title: "Booking.com",
    name: "Andrea",
    points: "10",
    coment: "El loft es muy lindo, muy bien equipado. Las chicas Miranda y Mariela amables y cordiales. La vista, el paisaje genial!!!",
    date: "2022-08-16"
  },{
    title: "Booking.com",
    name: "Samantha",
    points: "10",
    coment: "El loft divino, todo nuevo,con equipamiento completo de categoría y todas las comodidades. El wifi tuvo algunos cortes, y sin el no se podía usar la tv; Mariela super atenta se acercó para resolverlo.",
    date: "2022-06-21"
  },{
    title: "Booking.com",
    name: "Lourdes",
    points: "10",
    coment: "Los lofts están perfectamente bien equipados y son muy modernos y nuevos. Tuvimos un pequeño problemita con la cocina electrica, pero se solucionó.",
    date: "2022-07-12"
  }]

  return (
    <section
      ref={componentRef}
      className="w-full bg-[#9CC4B2] p-5 sm:p-10"
    >
      <h2 className="text-xl sm:text-3xl my-4 sm:my-10 font-bold text-center text-white">OPINIONES</h2>
      <div className="lg:px-20">
        <Swiper
          slidesPerView={md ? 3 : 1}
          slidesPerGroup={md ? 3 : 1}
          navigation
          pagination={{el: null}}
          modules={[Navigation, Pagination]}
        >
          {opinions.map((e,i)=>(
            <SwiperSlide>
              <div onClick={()=>window.open("https://www.booking.com/hotel/ar/loft-de-montana-raco.es.html")}
                key={i}
                className="rounded-2xl cursor-pointer text-lg w-52 mx-auto text-white bg-blue-700 p-5 flex flex-col items-start"
              >
                <h3 className="font-semibold">{e.title}</h3>
                <p>{e.name}</p>
                <span className="flex">{e.points}<img src={star} alt='star' className="ml-1 w-6 h-6" /></span>
                <p className="my-3 text-xs">{e.coment}</p>
                <p className="text-base">{e.date}</p>
              </div>
            </SwiperSlide>
            ))}
        </Swiper>
      </div>
      
    </section>
  );
});

export default Opinions;