import React, { useRef, useImperativeHandle, forwardRef } from "react";

import { useTranslation } from "react-i18next";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Lazy } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/lazy";

import angle from '../../assets/double-down-30.png';

import foto1 from '../../assets/lofts/LoftRaco_142.jpg';
import foto2 from '../../assets/lofts/Copia de LoftRaco_126.jpg';
import foto3 from '../../assets/lofts/LoftRaco_004.jpg';
import foto4 from '../../assets/lofts/LoftRaco_033.jpg';
import foto5 from '../../assets/lofts/LoftRaco_091.jpg';
import foto6 from '../../assets/lofts/LoftRaco_094.jpg';
import foto7 from '../../assets/lofts/LoftRaco_106.jpg';
import foto8 from '../../assets/lofts/LoftRaco_133.jpg';

const Lofts = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const fotos = [foto1,foto2,foto3,foto4,foto5,foto6,foto7,foto8]

  const componentRef = useRef();
  const scrollToLofts = () => {
    componentRef.current.scrollIntoView({ behavior: "smooth" });
  };
  useImperativeHandle(ref, () => {
    return {
      scroll: scrollToLofts,
    };
  });

  return (
    <section ref={componentRef} className="h-fit bg-[#B08D8C] flex flex-col lg:flex-row">
      <div className="w-full lg:w-1/3 p-5 sm:p-10 flex flex-col text-white">
        <p className="text-xl sm:text-3xl my-5 lg:my-20 font-semibold">"Tres Vientos - Lofts de Montaña"</p>
        <p className="lg:flex-1 text-lg sm:text-xl">
          Se encuentra en Raco, Tucumán, Argentina.<br/><br />
          Contamos con tres chalets completamente equipados, que ofrecen hermosas vistas y son de fácil acceso.
        </p>
        <p onClick={props.onDetails} className="font-semibold mt-4 text-lg sm:text-2xl flex items-center lg:justify-center">Detalles<img src={angle} alt='icon' className="w-7 ml-2 animate-bounce"/></p>
      </div>
      <div className="w-full lg:w-2/3 sm:p-10 lg:pl-0">
        <Swiper
          className="h-full"
          preloadImages={false}
          lazy={true}
          pagination={{
            clickable: true,
            type: "bullets"
          }}
          navigation
          speed={800}
          modules={[Pagination, Navigation, Lazy]}
        >
          {fotos.map((f,i)=>(
            <SwiperSlide>
              <img loading="lazy" src={f} alt='loft view' className="w-full object-cover swiper-lazy" />
              <div className="swiper-lazy-preloader" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
});

export default Lofts;
