import React, { useRef, useImperativeHandle, forwardRef } from "react";

import { useTranslation } from 'react-i18next';

import map from "../../assets/map.png";

const Details = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const componentRef = useRef();
  const scrollToDetails = () => {
    componentRef.current.scrollIntoView({ behavior: "smooth" });
  };
  useImperativeHandle(ref, () => {
    return {
      scroll: scrollToDetails,
    };
  });

  return (
    <section
      ref={componentRef}
      className="w-full bg-[#FDB54D] py-5 px-5 sm:px-32 md:px-10 xl:px-32 flex flex-col lg:flex-row text-white"
    >
      <div className="flex flex-col items-start justify-start mb-10 lg:mb-0 lg:w-1/2">
        <p className="text-xl sm:text-3xl font-semibold my-4 sm:my-10">Especificaciones</p>
        <p className="text-base sm:text-lg">
          <ul className="list-disc pl-5 sm:pl-10">
            <li className="mt-2 sm:mt-4">Cocina eléctrica completamente equipada.</li>
            <li className="mt-2 sm:mt-4">Parilla para asado.</li>
            <li className="mt-2 sm:mt-4">Aire acondicionado frio/calor.</li>
            <li className="mt-2 sm:mt-4">Habitación cama matrimonial doble.</li>
            <li className="mt-2 sm:mt-4">Wi-Fi.</li>
            <li className="mt-2 sm:mt-4">Estacionamiento privado.</li>
          </ul>
        </p>
      </div>
      <div className="flex flex-col lg:w-1/2">
        <p className="text-lg sm:text-xl font-semibold mb-2 sm:mb-6">Como llegar {'('}clickea para ir a Google Maps{')'}</p>
        <div onClick={()=>window.open("https://www.google.com.ar/maps/place/26%C2%B041'25.4%22S+65%C2%B025'41.4%22W/@-26.6916898,-65.4311405,15.75z/data=!4m4!3m3!8m2!3d-26.690388!4d-65.42816")}
          className="flex cursor-pointer justify-center items-center w-full h-fit sm:h-96"
        >
          <img src={map} alt='map' className="object-left w-full h-full object-contain" />
        </div>
      </div>
    </section>
  );
});

export default Details;
