import { useRef } from "react";
import Home from "./components/home/Home";
import Details from "./components/details/Details";
import Lofts from "./components/lofts/Lofts";
import Interesting from "./components/interesting/Insteresting";
import Contact from "./components/contact/Contact";
import Header from "./components/header/Header";
import Opinions from "./components/opinions/Opinions";

import wpLogoColor from "./assets/whatsapp.png";
import instacolor from './assets/instacolor.png';


const App = () => {
  const homeRef = useRef();
  const detailsRef = useRef();
  const loftsRef = useRef();
  const interestingRef = useRef();
  const opinionsRef = useRef();
  const contactRef = useRef();

  const scrollIntoHome = () => {
    homeRef.current.scroll();
  };
  const scrollIntoLofts = () => {
    loftsRef.current.scroll();
  };
  const scrollIntoDetails = () => {
    detailsRef.current.scroll();
  };
  const scrollIntoInteresting = () => {
    interestingRef.current.scroll();
  };
  const scrollIntoOpinions = () => {
    opinionsRef.current.scroll();
  };
  // const scrollIntoContact = () => {
  //   contactRef.current.scroll();
  // };

  return (
    <>
      <Header
        onHome={scrollIntoHome}
        onLofts={scrollIntoLofts}
        onDetails={scrollIntoDetails}
        onInteresting={scrollIntoInteresting}
        onOpinions={scrollIntoOpinions}
        // onContact={scrollIntoContact}
      />
      <main>
        <Home ref={homeRef} />
        <Lofts ref={loftsRef} onDetails={scrollIntoDetails}/>
        <Details ref={detailsRef}/>
        <Interesting ref={interestingRef}/>
        <Opinions ref={opinionsRef}/>
        <Contact ref={contactRef} />
        <div className="fixed z-30 bottom-0 right-0 m-3 lg:m-10 flex">
          <a href="https://wa.link/mqn14g" target='_BLANK' ><img className="object-cover object-center w-10 h-10 sm:w-14 sm:h-14 rounded-full mr-3" src={wpLogoColor} alt="wpLogo" /></a>
          <a href="https://www.instagram.com/tresvientos.raco/" target='_BLANK' ><img className="object-cover object-center w-10 h-10 sm:w-14 sm:h-14 rounded-full" src={instacolor} alt="wpLogo" /></a>
        </div>
      </main>
    </>
  );
};

export default App;
