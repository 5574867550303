import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
} from "react";

import { useTranslation } from "react-i18next";
import LOGO from '../../assets/TRES VIENTOS LETRAS SIN FONDO.png'
import foto from '../../assets/lofts/IMG_9774.jpg';

const Home = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const [opacity, setOpacity] = useState(false)
  const [loader, setLoader] = useState(true);

  const componentRef = useRef();
  const scrollToHome = () => {
    componentRef.current.scrollIntoView({ behavior: "smooth" });
  };
  useImperativeHandle(ref, () => {
    return {
      scroll: scrollToHome,
    };
  });

  useEffect(()=> {
    setTimeout(()=>{
      setOpacity(true)
    }, 1000)
    setTimeout(()=>{
      setLoader(false);
    }, 1500)
  }, [])

  // const fotos = [Slide5 ,Slide1, Slide2, Slide3, Slide4];

  return (
    <section ref={componentRef} className="min-h-screen flex items-center text-white">
      <div className={`${loader ? '' : 'hidden'} ${opacity ? 'opacity-0' : 'opacity-100'} transition-all duration-500 fixed w-full h-screen bg-[#C4BAB2] z-40 flex justify-center items-center`}>
        <div className="border-8 animate-spin border-b-0 border-r-0 border-[#9CC4B2] rounded-full w-20 h-20" />
      </div>
      <div className="absolute align-middle flex z-[5] flex-col justify-center pl-10 -mt-52 sm:-mt-40 md:pl-60">{/* */}
        <img src={LOGO} alt="Logo" className="w-64 sm:w-[400px] pb-8"/>
        <h1 className={`${opacity ? 'flex' : 'hidden'} flex md:w-[33vw] text-xl sm:text-4xl gap-2 text-shadow font-semibold flex-col justify-center`}>
          <p className="-translate-x-[50vw] animate-appear animation-delay-1">Desconéctate</p>
          <p className="-translate-x-[50vw] animate-appear animation-delay-2">Descansa</p>
          <p className="-translate-x-[50vw] animate-appear animation-delay-3">Disfruta</p>
        </h1>
      </div>
      <img src={foto} alt='Fondo' className="absolute left-0 top-0 w-full h-full object-cover" />
    </section>
  );
});

export default Home;
